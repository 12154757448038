import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { map, take, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { SidenavService } from './components/sidenav/sidenav.service';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private sidePane: SidenavService,
    private notification: NotificationsService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.authInfo.pipe(
      take(1),
      map(authInfo => !!authInfo),
      tap(auth => !auth ? this.openSidePane() : true)
    )
  }

  openSidePane() {
    this.sidePane.open();
    this.notification.showNotification({
      message: 'Please sign in to continue. Angular.jozi would like to know who you are to in case you may want to save your changes.',
      action: 'Okay'
    });

    // this.notification.notificationOpen$.next(true);
  }
}

import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { User } from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class UserDialogService {
  private openUserDialog = new BehaviorSubject<User>(null);
  openUserDialog$ = this.openUserDialog.asObservable();
  // public openDialog$: Subject<boolean> = new Subject();

  constructor() { }

  openDialog(user: User) {
    this.openUserDialog.next(user);
  }
}

import { Injectable, OnInit } from '@angular/core';
// import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { Talk } from '../models/talk';
import { Observable } from 'rxjs';
import { log } from 'util';
import { UsersService } from './users.service';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { AngularFirestore } from '@angular/fire/firestore';

export interface TalkLength {
  value: string;
  viewValue: string;
}

@Injectable({
  providedIn: 'root'
})
export class TalksService implements OnInit {

  private dbPath = '/talks';
  private upVotePath = '/talks/upVotes';
  private downVotePath = '/talks/downVotes';

  user: User;


  // upVotePathRef: AngularFireList<any>;
  // downVotePathRef: AngularFireList<any>;

  constructor(
    private db: AngularFirestore,
    private userService: UsersService
  ) {
    // this.talksRef = db.list(this.dbPath);
    // this.upVotePathRef = db.list(this.upVotePath);
    // this.downVotePathRef = db.list(this.downVotePath);

    this.userService.appUserInfo$.subscribe(
      retrivedUser => {
        if (retrivedUser) {
          this.user = this.userService.appUser;
        }
      });
  }

  ngOnInit(): void {

  }

  createTalk(talk: Talk) {
    talk.user = this.user
    return this.db.collection('talks').add({
      active: talk.active,
      date: talk.date.toISOString(),
      description: talk.description,
      title: talk.title,
      user: this.user,
      time: talk.time,
      image: talk.image
    });
  }

  //Talks
  updateTalk(key: string, value: any) {
    return this.db.collection('talks').doc(key).update(value).catch(error => this.handleError(error));
  }


  deleteTalk(key: string) {
    return this.db.collection('talks').doc(key).delete().catch(error => this.handleError(error));
  }

  getTalksList() {
    return this.db.collection('talks', ref => ref.orderBy('date', 'desc')).snapshotChanges()
      .pipe(
        map(results => {
          const publishedTalks = results.map(a => {
            const data = a.payload.doc.data() as any;
            const key = a.payload.doc.id;
            return { key, ...data };
          })
          return {
            talks: publishedTalks.filter(
              x => x['active'] == true && x['isPrevious'] != true)
          }
        })
      )
  }

  getUserTalksList(value) {
    return this.db.collection('talks', ref => ref.where('user.uid', '==', value).orderBy('date', 'desc')).snapshotChanges()
      .pipe(
        map(results => {
          const publishedTalks = results.map(a => {
            const data = a.payload.doc.data() as any;
            const key = a.payload.doc.id;
            return { key, ...data };
          })
          return {
            talks: publishedTalks
          }
        })
      )
  }

  getPreviousList() {
    return this.db.collection('talks', ref => ref.orderBy('date', 'desc')).snapshotChanges()
    .pipe(
      map(results => {
        const publishedTalks = results.map(a => {
          const data = a.payload.doc.data() as any;
          const key = a.payload.doc.id;
          return { key, ...data };
        })
        return {
          talks: publishedTalks.filter(
            x => x['isPrevious'] == true)
        }
      })
    )
  }

  getRecentList(value) {
    return this.db.collection('talks', ref => ref.where('date', '>=', value).orderBy('date', 'desc')).snapshotChanges()
      .pipe(
        map(results => {
          const publishedTalks = results.map(a => {
            const data = a.payload.doc.data() as any;
            const key = a.payload.doc.id;
            return { key, ...data };
          })
          return {
            talks: publishedTalks.filter(
              x => x['active'] == true && x['isPrevious'] != true)
          }
        })
      )
  }

  deleteAll(): void {
    // this.talksRef.remove().catch(error => this.handleError(error));
  }

  getTalk(key: string): any {
    return this.db.collection('talks').doc(key).snapshotChanges();
  }

  //Votes
  updateUpVote(key: string) {
    return this.db.collection('talks/').doc(key).collection('upVotes').add(this.user)
      .catch(error => this.handleError(error));
  }

  setPrevious(key: string) {
    return this.db.collection('talks').doc(key).update({ isPrevious: true }).catch(error => this.handleError(error));
  }

  updateDownVote(key: string) {
    return this.db.collection('talks/').doc(key).collection('downVotes').add(this.user)
      .catch(error => this.handleError(error));
  }

  removeVote(keyCollection: string, voteDirection: boolean, key: string) {
    const collection = voteDirection ? 'upVotes' : 'downVotes';
    return this.db.collection('talks/').doc(keyCollection).collection(collection).doc(key).delete()
      .catch(error => this.handleError(error));
  }

  getUpVotes(key: string) {
    return this.db.collection('talks/').doc(key).collection('/upVotes').snapshotChanges()
      .pipe(
        map(results => {
          const votedUsers = results.map(a => {
            const data = a.payload.doc.data();
            const key = a.payload.doc.id;
            return { key, ...data };
          })
          return {
            count: results.length,
            vote: votedUsers.find(user => ((this.user && this.user.uid) === user['uid']), false),
          }
        }
        ))
  }

  getDownVotes(key: string) {
    return this.db.collection('talks/').doc(key).collection('/downVotes').snapshotChanges()
      .pipe(
        map(results => {
          const votedUsers = results.map(a => {
            const data = a.payload.doc.data();
            const key = a.payload.doc.id;
            return { key, ...data };
          })
          return {
            count: results.length,
            vote: votedUsers.find(user => ((this.user && this.user.uid) === user['uid']), false),
          }
        }
        ))
  }

  getLikes(key: string) {
    return this.db.collection('talks/').doc(key).collection('/likes').snapshotChanges()
      .pipe(
        map(results => {
          const votedUsers = results.map(a => {
            const data = a.payload.doc.data();
            const key = a.payload.doc.id;
            return { key, ...data };
          })
          return {
            count: results.length,
            like: votedUsers.find(user => ((this.user && this.user.uid) === user['uid']), false),
          }
        }
        ))
  }

  getDislikes(key: string) {
    return this.db.collection('talks/').doc(key).collection('/likes').snapshotChanges()
      .pipe(
        map(results => {
          const votedUsers = results.map(a => {
            const data = a.payload.doc.data();
            const key = a.payload.doc.id;
            return { key, ...data };
          })
          return {
            count: results.length,
            like: votedUsers.find(user => ((this.user && this.user.uid) === user['uid']), false),
          }
        }
        ))
  }

  updateLikes(key: string) {
    return this.db.collection('talks/').doc(key).collection('likes').add(this.user)
      .catch(error => this.handleError(error));
  }

  removeLikes(keyCollection: string, key: string) {

    return this.db.collection('talks/').doc(keyCollection).collection('likes').doc(key).delete()
      .catch(error => this.handleError(error));
  }

  private handleError(error) {
    console.log('Error in opereation', error);
  }
}

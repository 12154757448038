import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute } from "@angular/router";
import { TalksService } from 'src/app/services/talks.service';


@Injectable()
export class TalkResolver implements Resolve<any> {

    constructor(public talkService: TalksService) { }

    resolve(route: ActivatedRouteSnapshot) {

        return new Promise((resolve, reject) => {
            let talkKey = route.paramMap.get('id');
            this.talkService.getTalk(talkKey)
                .subscribe(
                    data => {
                        resolve(data);
                    }
                );
        })
    }
}

import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public authInfo: Observable<firebase.User>;

  constructor(
    private afAuth: AngularFireAuth
  ) {
    this.authInfo = this.afAuth.authState;

    // this.afAuth.auth.onAuthStateChanged(user => {
    //   if (user) {
    //     console.log('state changed ', user);
    //   }
    // });
  }

  login(provider: string) {
    // return this.afAuth.auth.signInWithPopup(this.getProvider(provider));  
    this.afAuth.auth.signInWithRedirect(this.getProvider(provider));
    return this.afAuth.auth.getRedirectResult();
  }

  logout() {
    this.afAuth.auth.signOut().then(() => {
      console.log('logged out')
    });
  }

  getProvider(provider: String) {
    var providerObj = null;
    switch (provider) {
      case 'google':
        providerObj = new firebase.auth.GoogleAuthProvider();
        break;
      case 'meetup':
        // providerObj = new firebase.auth.OAuthProvider();
        // // providerObj.providerId = 'secure.meetup.com'
        // // providerObj.addScope('authorization');
        // // providerObj.addScope('code');
        // // providerObj.credential('5d540nh660eni39d4fochhg4fh', 'pfrdbtpn39p59e323iff860k96');
        // // providerObj.setCustomParameters(
        // //   {
        // //     'redirect_uri': 'https://localhost ',
        // //     'code': 'results'
        // //   });
        console.log('trying meetup', providerObj);
        break;
      default:
        break;
    }
    return providerObj;
  }
}

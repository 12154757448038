import { Component, OnInit, AfterViewInit, AfterViewChecked } from '@angular/core';
import { TalksService } from 'src/app/services/talks.service';
import { map, switchMap } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { UsersService } from 'src/app/services/users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { FilterService, FilterTalk } from 'src/app/services/filter.service';

@Component({
  selector: 'app-talks',
  templateUrl: './talks.component.html',
  styleUrls: ['./talks.component.scss']
})

export class TalksComponent implements OnInit, AfterViewChecked {

  ngAfterViewChecked(): void {

  }

  talks: Array<any>;
  user;
  selectedPage: string;

  selectedTalk;
  selectedMenu: FilterTalk;
  constructor(
    private talksService: TalksService,
    private userService: UsersService,
    private router: ActivatedRoute,
    private filter: FilterService
  ) {
    this.userService.appUserInfo$.subscribe(
      retrivedUser => {
        // if (retrivedUser) {
        this.user = this.userService.appUser;
        // }
      });

    this.filter.filter$.subscribe(filter => {
      this.getSelectedTalk(filter);
      this.scrollToTop();
    })
  }

  ngOnInit() {
    // this.getTalkList();
    this.router.paramMap.subscribe(
      params => {
        this.selectedTalk = params.get('selected')
      }
    );
  }

  getSelectedTalk(filter: FilterTalk) {
    switch (filter) {
      case FilterTalk.User:
        this.getUserTalkList();
        this.selectedPage = 'My Talks';
        break;
      case FilterTalk.New:
        this.getRecentTalkList();
        this.selectedPage = 'Recent Talks';
        break;
      case FilterTalk.Previous:
        this.getPreviousTalkList();
        this.selectedPage = 'Previous Talks';
        break;
      default:
        this.getTalkList();
        this.selectedPage = 'All Talks'
        break;
    }
  }

  scrollToTop() {
    let el = document.getElementsByClassName('selected')[0];
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => { this.selectedTalk = null; }, 5000);
    } else {
      let topEl = document.getElementsByClassName('title-container')[0];
      if (topEl) {
        topEl.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  public onResize(event) {
  }

  getTalkList(): any {
    this.talksService.getTalksList()
      .subscribe(results => {
        this.talks = results.talks;
      });
    // this.filter.updatedSelection(FilterTalk.None);
  }

  getUserTalkList(): any {
    this.talksService.getUserTalksList(this.user.uid)
      .subscribe(data => {
        this.talks = data.talks;
      });
    // this.filter.updatedSelection(FilterTalk.User);
  }

  getRecentTalkList(): any {
    const lastMonth = new Date(
      (new Date()).setMonth((new Date()).getMonth() - 1)
    );

    this.talksService.getRecentList(lastMonth.toISOString())
      .subscribe(results => {
        this.talks = results.talks;
      });

    // this.filter.updatedSelection(FilterTalk.New);
  }

  getPreviousTalkList(): any {
    this.talksService.getPreviousList()
      .subscribe(data => {
        this.talks = data.talks;
      });
    // this.filter.updatedSelection(FilterTalk.User);
  }
}

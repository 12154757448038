import { Component, OnInit, AfterViewChecked, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Talk } from 'src/app/models/talk';
import { TalksService, TalkLength } from 'src/app/services/talks.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from 'src/app/models/user';
import { UsersService } from 'src/app/services/users.service';
import { map, finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { SidenavService } from 'src/app/services/components/sidenav/sidenav.service';
import { FilterService, FilterTalk } from 'src/app/services/filter.service';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { FileHandle } from 'src/app/directives/drapdrop.directive';
import { createHostListener } from '@angular/compiler/src/core';

@Component({
  selector: 'app-talk-create',
  templateUrl: './talk-create.component.html',
  styleUrls: ['./talk-create.component.scss']
})

export class TalkCreateComponent implements OnInit, AfterViewChecked, AfterViewInit {
  @ViewChild('uploadclick') myDiv: ElementRef;

  ngAfterViewChecked(): void {
    // let el = document.getElementsByClassName('title')[0];
    // if (el) {
    //   el.scrollIntoView({ behavior: 'smooth' });
    // }
  }
  panelOpenState = false;
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  imageUrl: string;
  files: FileHandle[] = [];

  talk: Talk = new Talk();
  talkForm: FormGroup;
  user;
  showProgress: boolean;

  submitted = false;
  formNew: boolean;

  talkLengths: TalkLength[] = [
    { value: '10', viewValue: '10min' },
    { value: '20', viewValue: '20min' },
    { value: '40', viewValue: '40min' }
  ];

  validation_messages = {
    'title': [
      { type: 'required', message: 'Title is required.' },
      { type: 'minlength', message: 'Please add more details.' }
    ],
    'description': [
      { type: 'required', message: 'Abstract is required.' },
      { type: 'minlength', message: 'Please add more details.' }
    ],
    'age': [
      { type: 'required', message: 'Age is required.' },
    ],
    'slide': [
      { type: 'pattern', message: 'Please select a valid url.' },
    ],
    'code': [
      { type: 'pattern', message: 'Please select a valid url.' },
    ]
  };


  constructor(
    private talksService: TalksService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private userService: UsersService,
    private sideNav: SidenavService,
    private selectedMenu: FilterService,
    private storage: AngularFireStorage
  ) {
    this.userService.appUserInfo$.subscribe(
      retrivedUser => {
        if (retrivedUser) {
          this.user = this.userService.appUser;
        }
      });
  }

  ngOnInit() {
    this.route.data.subscribe(routeData => {
      let data = routeData['data'];
      if (data) {
        this.formNew = false;
        this.talk = data.payload.data();
        this.talk.key = data.payload.id;
      } else {
        this.formNew = true;
      }
      this.createForm();
    })

    // window.addEventListener("dragover", function (e) {
    //   let a = e || event;
    //   a.preventDefault();
    // }, false);
    // window.addEventListener("drop", function (e) {
    //   let a = e || event;
    //   a.preventDefault();
    // }, false);

  }

  ngAfterViewInit() {
    document.getElementsByTagName('mat-sidenav-content')[0].scrollTo(0, 0);
  }
  
  newCustomer(): void {
    this.submitted = false;
    this.talk = new Talk()
  }

  createForm() {
    this.talkForm = this.fb.group({
      title: [this.talk.title, [Validators.required, Validators.minLength(10)]],
      description: [this.talk.description, [Validators.required, Validators.minLength(50)]],
      time: [this.talk.time],
      slide: [this.talk.slide, [Validators.pattern('https?://.+')]],
      code: [this.talk.code, [Validators.pattern('https?://.+')]],
      // image: [this.talk.image]
    });

    this.imageUrl = this.talk.image;
  }

  save() {
    return this.talksService.createTalk(this.talk);
  }

  onSubmit(value) {
    if (this.formNew) {
      this.submitNewTalk(value);
      this.selectedMenu.updatedSelection(FilterTalk.User, false);
    } else if (!this.formNew) {
      this.updateTalk(value);
    }
  }

  updateTalk(value: any): any {
    this.submitted = true;
    this.talk.description = value.description;
    this.talk.title = value.title;
    this.talk.time = value.time;
    this.talk.slide = value.slide;
    this.talk.code = value.code;
    this.talk.image = this.imageUrl === undefined ? null : this.imageUrl;
    this.talksService.updateTalk(this.talk.key, this.talk).then(
      ref => {
        this.router.navigate(['/topic', { selected: this.talk.key }]);
      }
    );
  }

  submitNewTalk(value: any): any {
    this.submitted = true;
    this.talk.active = false;
    this.talk.date = (new Date());
    this.talk.description = value.description;
    this.talk.title = value.title;
    this.talk.time = value.time;
    this.talk.slide = value.slide;
    this.talk.code = value.code;
    this.talk.image = this.imageUrl === undefined ? null : this.imageUrl;
    this.talksService.createTalk(this.talk).then(
      ref => {
        this.talksService.updateDownVote(ref.id);
        this.talksService.updateUpVote(ref.id);
        this.router.navigate(['/topic', { selected: ref.id }]);
      }
    );
  }

  toggleSideNav() {
    this.sideNav.toggle();
  }

  cancel(): any {
    this.router.navigate(['/topic']);
  }

  deleteTalk(key) {
    this.talksService.deleteTalk(key);
  }

  filesDropped(files: FileHandle[]): void {
    this.files = files;
  }

  fileSelected(event) {
    this.files = event.target.files;
  }

  uploadFile() {
    this.showProgress = true;
    const file = this.files[0].file
    const filePath = `images/${file.name}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = fileRef.getDownloadURL();
        fileRef.getMetadata().subscribe(val => {
          this.imageUrl = val.md5Hash
        })
        this.showProgress = false;
      })
    )
      .subscribe()
  }

  openFileDialog(stuff) {
    this.myDiv.nativeElement.click();
  }

  removeFiles() {
    this.files = [];
    this.talk.image = "";
    this.imageUrl = "";
    this.downloadURL = new Observable<string>();
  }
}

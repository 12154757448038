import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

//TODO: Fix scroll

@Component({
  selector: 'crm-command-bar',
  templateUrl: './command-bar.component.html',
  styleUrls: ['./command-bar.component.scss'],
  // animations: [
  //   trigger('fade',
  //     [
  //       state('void', style({ opacity: 0 })),
  //       transition(':enter', [animate(300)]),
  //       transition(':leave', [animate(500)]),
  //     ]
  //   )]
})
export class CommandBarComponent implements OnInit {

  sticky: boolean = false;
  elementPosition: any;

  @ViewChild('stickyMenu') menuElement: ElementRef;
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
   
  console.log(this.elementPosition)

  const content = document.querySelector('.stickyMenu');
    const scroll$ = fromEvent(content, 'window:scroll').pipe(map(() => content));

    scroll$.subscribe(element => {
      console.log('scorll event', element);
    });
  }


  @HostListener('window:scroll', [])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    console.log('scorll event');
    
    // const fixedTopOffset = this.elementPosition.top + window.pageYOffset;
    console.log('handleScroll', windowScroll,  this.elementPosition);
    if (windowScroll >=  this.elementPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }
}

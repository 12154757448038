import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sponsers',
  templateUrl: './sponsers.component.html',
  styleUrls: ['./sponsers.component.scss']
})
export class SponsersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationsService } from './notifications.service';

export enum FilterTalk {
  None = 0,
  User,
  New,
  Previous
}

@Injectable({
  providedIn: 'root'
})


export class FilterService {
  private selectedfilter = new BehaviorSubject<FilterTalk>(FilterTalk.None);
  filter$ = this.selectedfilter.asObservable();
  constructor(
    private router: Router,
    public notification: NotificationsService
  ) { }

  updatedSelection(selected: FilterTalk, showMessage: boolean) {
    if (this.router.url.includes('/topic/create') || this.router.url.includes('/topic/edit')) {
      if (showMessage) {
        this.notification.showNotification({
          message: 'Please save or cancel your changes before continuing.',
          action: 'Okay'
        });
      }
    } else {
      this.selectedfilter.next(selected);
    }
  }
}

import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDialogService } from 'src/app/services/components/user-dialog/user-dialog.service';
import { User } from 'src/app/models/user';
import { UsersService } from 'src/app/services/users.service';
import { map } from 'rxjs/operators';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {

  user: User;

  constructor(
    public dialog: MatDialog,
    private userDialog: UserDialogService,
    private userSertvice: UsersService
  ) {
    this.userDialog.openUserDialog$.subscribe(talkUser => {
      this.userSertvice.getUserByUID(talkUser.uid)
        .pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data() as User;
            return data;
          }))).subscribe(data =>
            data.map(user => {
              this.user = user;
            })
          );
    })
  }

  openExternal(url, type) {
    let prefix;
    switch (type) {
      case 'web':
        prefix = 'http://www.';
        break;
      case 'twitter':
        prefix = 'https://twitter.com/';
        break;
      case 'linkedin':
        prefix = 'https://www.linkedin.com/in/';
        break;
      case 'github':
        prefix = 'https://github.com/';
        break;

      default:
        break;
    }
    window.open(`${prefix}${url}`, '_blank');
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { Talk } from 'src/app/models/talk';
import { TalksService } from 'src/app/services/talks.service';
import { User } from 'src/app/models/user';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications.service';
import { SidenavService } from 'src/app/services/components/sidenav/sidenav.service';
import { UsersService } from 'src/app/services/users.service';
import { UserDialogService } from 'src/app/services/components/user-dialog/user-dialog.service';
@Component({
  selector: 'app-talk-detail',
  templateUrl: './talk-detail.component.html',
  styleUrls: ['./talk-detail.component.scss']
})
export class TalkDetailComponent implements OnInit {

  @Input() talk: any;
  @Input() user: any;
  @Input() selected: any;

  upVoteCount: number;
  downVoteCount: number;

  canUpVote: boolean;
  canDownVote: boolean;

  likeCount: number;

  canLike: boolean;

  voteKey: any;

  fireworks: boolean

  constructor(
    private talksService: TalksService,
    private router: Router,
    private sidePane: SidenavService,
    private notification: NotificationsService,
    private userService: UsersService,
    private userDialogService: UserDialogService
  ) {
  }

  ngOnInit() {
    this.talksService.getUpVotes(this.talk.key).pipe(
      map(results => {
        if (results.vote) {
          this.canUpVote = false;
          this.setVoteKey(results.vote.key);
        } else {
          this.canUpVote = true;
        }
        this.upVoteCount = results.count;
      })).subscribe()

    this.talksService.getDownVotes(this.talk.key).pipe(
      map(results => {
        if (results.vote) {
          this.canDownVote = false;
          this.setVoteKey(results.vote.key);
        } else {
          this.canDownVote = true;
        }
        this.downVoteCount = results.count;
      }
      )).subscribe()

    if (this.talk && this.talk.isPrevious) {
      this.talksService.getLikes(this.talk.key).pipe(
        map(results => {
          if (results.like) {
            this.canLike = false;
            this.setVoteKey(results.like.key);
          } else {
            this.canLike = true;
          }
          this.likeCount = results.count;
        })).subscribe()
    }
  }

  setVoteKey(value) {
    if (value)
      this.voteKey = value;
  }

  updateActive(key, isActive: boolean) {
    this.talksService.updateTalk(key, { active: isActive });
    this.router.navigate(['/topic', { selected: key }]);
  }

  getTalk(key: string) {
    this.talksService.getTalk(key);
    this.router.navigate(['/topic/edit/' + key]);
  }

  deleteTalk(key: string) {
    this.talksService.deleteTalk(key);
    this.router.navigate(['/topic']);
  }

  voteUp(keyCollection: string) {
    if (!this.canDownVote) {
      this.talksService.removeVote(keyCollection, false, this.voteKey);
    } else if (this.canUpVote) {
      this.talksService.updateUpVote(keyCollection);
    }
  }

  voteDown(keyCollection: string) {
    if (!this.canUpVote) {
      this.talksService.removeVote(keyCollection, true, this.voteKey);
    } else if (this.canDownVote) {
      this.talksService.updateDownVote(keyCollection);
    }
  }

  like(keyCollection: string) {
    if (!this.canLike) {
      this.talksService.removeLikes(keyCollection, this.voteKey);
    } else {
      this.talksService.updateLikes(keyCollection);
    }
  }

  voteNouser(keyCollection: string) {
    this.sidePane.open();
    this.notification.showNotification({
      message: 'Please sign in to vote for topics.',
      action: 'Okay'
    });

    this.userService.appUserInfo$.subscribe(
      retrivedUser => {
        if (retrivedUser) {
          this.router.navigate(['/topic', { selected: keyCollection }]);
        }
      });
  }

  openUserDetails(user: User) {
    this.userDialogService.openDialog(user);
  }

  setPrevious(key) {
    this.talksService.setPrevious(key);
  }

  showfireworks() {
    this.fireworks = true;
    setTimeout(() => { this.fireworks = null; }, 8000);
  }

  openExternal(url) {
    window.open(`${url}`, '_blank');
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from './services/authentication.service';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModuleModule } from './material-module.module';

import { FlexLayoutModule } from '@angular/flex-layout';

import { HomeComponent } from './home/home.component';
import { EventsComponent } from './events/events.component';
import { SpeakersComponent } from './speakers/speakers.component';
import { OrganizersComponent } from './organizers/organizers.component';
import { SponsersComponent } from './sponsers/sponsers.component';
import { CodeofconductComponent } from './codeofconduct/codeofconduct.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { AuthGuardService } from './services/auth-guard.service';

import { SubmitTalkComponent } from './pages/submit-talk/submit-talk.component';
import { SidenavService } from './services/components/sidenav/sidenav.service';
import { DialogComponent } from './components/dialog/dialog.component';
import { SafePipe } from './pipes/safe.pipe';
import { TalksComponent } from './pages/talks/talks.component';
import { TalkDetailComponent } from './pages/talk-detail/talk-detail.component';
import { TalkCreateComponent } from './pages/talk-create/talk-create.component';
import { TalkEditComponent } from './pages/talk-edit/talk-edit.component';
import { TalksService } from './services/talks.service';
import { AngularFireDatabaseModule, AngularFireDatabase } from '@angular/fire/database';
import { CommandBarComponent } from './components/command-bar/command-bar.component';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { TalkResolver } from './pages/talk-create/talk-resolver';
import { NotificationsService } from './services/notifications.service';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { FilterService } from './services/filter.service';
import { LoaderComponent } from './components/loader/loader.component';
import { UserDialogService } from './services/components/user-dialog/user-dialog.service';
import { AngularFireStorageModule, AngularFireStorage } from '@angular/fire/storage';
import { DrapdropDirective } from './directives/drapdrop.directive';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    EventsComponent,
    SpeakersComponent,
    OrganizersComponent,
    SponsersComponent,
    CodeofconductComponent,
    SubmitTalkComponent,
    DialogComponent,
    CommandBarComponent,
    SafePipe,
    TalksComponent,
    TalkDetailComponent,
    TalkCreateComponent,
    TalkEditComponent,
    NotificationsComponent,
    LoaderComponent,
    DrapdropDirective
  ],
  entryComponents: [DialogComponent, NotificationsComponent, LoaderComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MaterialModuleModule,
    FlexLayoutModule,

    FormsModule,
    ReactiveFormsModule,

    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [
    AuthenticationService,
    AuthGuardService,
    SidenavService,
    TalksService,
    TalkResolver,
    NotificationsService,
    FilterService,
    UserDialogService,
    AngularFirestore,
    AngularFireDatabase,
    AngularFireStorage
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-codeofconduct',
  templateUrl: './codeofconduct.component.html',
  styleUrls: ['./codeofconduct.component.scss']
})
export class CodeofconductComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

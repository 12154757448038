import { User } from "./user";
import { MatDatepicker } from "@angular/material/datepicker";

export class Talk {
    // constructor(_author: string, _userId: string) {
    //     this.author = _author;
    //     this.userId = _userId;
    // }
    key: string;
    description: string;
    title: string;
    date: Date;
    active: boolean;
    upVotes: any;
    downVotes: any;
    time: number;
    user: User;
    slide: string;
    code: string;
    image: any;
}
import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { DialogComponent } from './components/dialog/dialog.component';
import { AuthenticationService } from './services/authentication.service';

import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { UrlResolver } from '@angular/compiler';
import { UsersService } from './services/users.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from './models/user';
import { map } from 'rxjs/operators';
import { NotificationsService } from './services/notifications.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SidenavService } from './services/components/sidenav/sidenav.service';
import { FilterService, FilterTalk } from './services/filter.service';
import { UserDialogService } from './services/components/user-dialog/user-dialog.service';
import { Talk } from './models/vote';
import { TalksService } from './services/talks.service';
export interface Area {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  @ViewChild('sidenav') public sidePane: MatSidenav;
  user;
  appUser;
  areas: Area[] = [
    { value: 'WEB', viewValue: 'Online' },
    { value: 'PTA', viewValue: 'Pretoria/Centurion area' },
    { value: 'JHB', viewValue: 'Johanessburg/Rosebank area' }
  ];

  compareAreas(o1: any, o2: any): boolean {
    return o1.value == o2.value && o1.viewValue == o2.viewValue;
  }

  userForm: FormGroup;
  filter: FilterTalk;
  previousTalks: any[];

  constructor(
    private sideNav: SidenavService,
    private userInfo: UsersService,
    private dialog: MatDialog,
    public router: Router,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    public notification: NotificationsService,
    private selectedMenu: FilterService,
    private userDialog: UserDialogService,
    private talksService: TalksService,
    private userDialogService: UserDialogService
  ) {
    this.user = userInfo.authUser;

    this.userInfo.appUserInfo$.subscribe(
      retrivedUser => {
        if ((retrivedUser != null) && (JSON.stringify(retrivedUser) !== '{}')) {
          this.appUser = retrivedUser;
          this.createForm();
        }
      });

    this.notification.notificationDialog$.subscribe(message => {
      this.snackBar.open(message.message, message.action, {
        duration: 8000,
      });
    });

    this.selectedMenu.filter$.subscribe(filter => {
      this.filter = filter;
    })

    this.userDialog.openUserDialog$.subscribe(user => {
      if (user) {
        this.openDialog();
      }
    })

    this.talksService.getPreviousList()
      .subscribe(data => {
        this.previousTalks = data.talks
          .sort((a, b) => (a['date'] > b['date']) ? 1 : ((b['date'] > a['date']) ? -1 : 0))
          .splice(2);
      });
  }

  ngOnInit() {
    this.sideNav.setSidenav(this.sidePane);
  }

  createForm() {
    this.userForm = this.fb.group({
      about: [this.appUser.about],
      contactNumber: [this.appUser.contactNumber],
      area: [this.appUser.preferedLocation],
      website: [this.appUser.website],
      twitterUrl: [this.appUser.twitterUrl],
      github: [this.appUser.github],
      linkedInUrl: [this.appUser.linkedInUrl],
      newsletter: [this.appUser.newsletter],
    });
  }

  close(reason: string) {
    // this.reason = reason;
    this.sidePane.close();
  }

  logOut() {
    this.userInfo.logOut();
  }

  logIn(provider: string) {
    this.userInfo.logIn(provider);
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    this.dialog.open(DialogComponent, dialogConfig);
  }

  openExternal(url) {
    window.open(url, 'Invite friends', 'right');
  }

  onSubmit(value) {
    if (this.appUser.contactNumber) value.contactNumber = this.appUser.contactNumber;
    if (this.appUser.about) value.about = this.appUser.about;
    if (this.appUser.area) value.area = this.appUser.area;
    if (this.appUser.website) value.website = this.appUser.website;
    if (this.appUser.twitterUrl) value.twitterUrl = this.appUser.twitterUrl;
    if (this.appUser.github) value.github = this.appUser.github;
    if (this.appUser.linkedInUrl) value.linkedInUrl = this.appUser.linkedInUrl;
    if (this.appUser.newsletter) value.newsletter = this.appUser.newsletter;
    this.userInfo.updateUser(this.appUser.id, value);

    this.notification.showNotification({
      message: 'Thank you. Your changes have been updated.',
      action: 'Okay'
    });

    this.sidePane.close();
  }

  getTalkList() {
    this.selectedMenu.updatedSelection(FilterTalk.None, true);
  }

  getUserTalkList() {
    this.selectedMenu.updatedSelection(FilterTalk.User, true);
  }

  getRecentTalkList() {
    this.selectedMenu.updatedSelection(FilterTalk.New, true);
  }

  getPreviousTalkList() {
    this.selectedMenu.updatedSelection(FilterTalk.Previous, true);
  }

  openUserDetails(user: User) {
    this.userDialogService.openDialog(user);
  }

  openExternalUrl(url) {
    window.open(`${url}`, '_blank');
  }

  sponser() {
    console.log('sponser user', this.appUser)
    if (this.appUser) {
      this.userInfo.addSponserInterest(this.appUser);
      this.notification.showNotification({
        message: `Thank you ${this.appUser.name === undefined ? "" : this.appUser.name}. We will contact you regarding your interest in becoming a sponser.`,
        action: 'Okay'
      });
    } else {
      this.notification.showNotification({
        message: 'You interest in becoing a sponser could not be recorded. Please log in so that we can get your contact details.',
        action: 'Okay'
      });
    }
  }
}

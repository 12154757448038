import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from "./home/home.component";
import { EventsComponent } from "./events/events.component";
import { OrganizersComponent } from "./organizers/organizers.component";
import { SpeakersComponent } from "./speakers/speakers.component";
import { SponsersComponent } from "./sponsers/sponsers.component";
import { CodeofconductComponent } from "./codeofconduct/codeofconduct.component";

import { SubmitTalkComponent } from "./pages/submit-talk/submit-talk.component";

import { AuthGuardService } from './services/auth-guard.service';
import { TalkDetailComponent } from './pages/talk-detail/talk-detail.component';
import { TalkCreateComponent } from './pages/talk-create/talk-create.component';
import { TalkEditComponent } from './pages/talk-edit/talk-edit.component';
import { TalksComponent } from './pages/talks/talks.component';
import { TalkResolver } from './pages/talk-create/talk-resolver';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'events', component: EventsComponent },
  { path: 'organizers', component: OrganizersComponent },
  { path: 'speakers', component: SpeakersComponent },
  { path: 'sponsers', component: SponsersComponent },
  { path: 'codeofconduct', component: CodeofconductComponent },
  { path: 'submittalk', component: SubmitTalkComponent, canActivate: [AuthGuardService] },
  {
    path: 'topic', component: TalksComponent, canActivate: [AuthGuardService]
  },
  {
    path: 'topic/details', component: TalkDetailComponent,
    data: { title: 'Topic Details' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'topic/create', component: TalkCreateComponent,
    data: { title: 'Create Topic' },
    canActivate: [AuthGuardService]
  },
  {
    path: 'topic/edit/:id', component: TalkCreateComponent,
    data: { title: 'Edit Topic' },
    resolve: { data: TalkResolver },
    canActivate: [AuthGuardService]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

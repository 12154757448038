import { Injectable, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationService } from './authentication.service';
import { map, switchMap, filter } from 'rxjs/operators';
import { User } from '../models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService implements OnInit {
  private appUserInfo = new BehaviorSubject<any>({});
  public appUserInfo$ = this.appUserInfo.asObservable();

  private newUserCreated = new BehaviorSubject<any>({});
  public newUserCreated$ = this.newUserCreated.asObservable();
  authUser;
  appUser;

  private dbPath = '/users';

  constructor(
    private db: AngularFirestore,
    private auth: AuthenticationService,
    public notification: NotificationsService) {
    this.authUser = auth.authInfo;

    this.auth.authInfo.pipe(
      filter(retrivedUser => retrivedUser != null),
      switchMap(retrivedUser => this.getUserByUID(retrivedUser.uid)
        .pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data() as User;
            const id = a.payload.doc.id;
            this.appUser = { id, ...data };
            this.appUserInfo.next(this.appUser)
          })
          )))).subscribe();
  }

  ngOnInit() {
  }

  logOut() {
    this.auth.logout();
    this.appUser = null;
    this.appUserInfo.next(null);
  }

  logIn(provider: string) {
    this.auth.login(provider).then(_ => {
      this.notification.showNotification({
        message: 'Remember to update your details after login.',
        action: 'Okay'
      });
    })
      .catch(error => {
        this.notification.showNotification({
          message: `Log in failed. ${error.message}`,
          action: 'Okay'
        });
      });
  }

  getUser(userKey) {
    return this.db.collection('users').doc(userKey).snapshotChanges();
  }

  updateUser(userKey, value) {
    // value.nameToSearch = value.name.toLowerCase();
    return this.db.collection('users').doc(userKey).update(value);
  }

  deleteUser(userKey) {
    return this.db.collection('users').doc(userKey).delete();
  }

  getUsers() {
    return this.db.collection('users').snapshotChanges();
  }

  searchUsers(searchValue) {
    return this.db.collection('users', ref => ref.where('nameToSearch', '>=', searchValue)
      .where('nameToSearch', '<=', searchValue + '\uf8ff'))
      .snapshotChanges()
  }

  getUserByUID(uid) {
    return this.db.collection('users', ref => ref.where('uid', '==', uid))
      .snapshotChanges();
  }

  searchUsersByAge(value) {
    return this.db.collection('users', ref => ref.orderBy('age').startAt(value)).snapshotChanges();
  }

  // createUser(value) {
  //   return this.db.collection('users').add({
  //     uid: value.user.uid,
  //     name: value.user.displayName,
  //     email: value.user.email,
  //     providerId: value.additionalUserInfo.providerId,
  //     photoUrl: value.user.photoURL,
  //     contactNumber: value.user.phoneNumber
  //   });
  // }

  addSponserInterest(value) {
    return this.db.collection('sponser').add({
      uid: value.uid,
      name: value.displayName === undefined ? null : value.displayName,
      email: value.email,
      contactNumber: value.phoneNumber === undefined ? null : value.phoneNumber
    });
  }

  private handleError(error) {
    console.log(error);
  }
}

import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

export interface NotificationMessage {
  message: string;
  action: string;
}

@Injectable({
  providedIn: 'root'
})

export class NotificationsService {
  private notification = new Subject<NotificationMessage>();
  notificationDialog$ = this.notification.asObservable();
  constructor() { }

  showNotification(message: NotificationMessage) {
    this.notification.next(message);
  }
}

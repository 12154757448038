import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-talk-edit',
  templateUrl: './talk-edit.component.html',
  styleUrls: ['./talk-edit.component.scss']
})
export class TalkEditComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { NotificationsService, NotificationMessage } from '../../services/notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  public message;
//TODO: Cater for template and use
  constructor(
    public notification: NotificationsService
  ) {
    // this.notification.notification$.subscribe(message => {
    //   console.log('Message', this.message)
    //   if (message) {
    //     this.message = message;
    //   }
    // });
  }

  ngOnInit() {
  }

}
